import React from 'react';
import styled from 'styled-components/macro';

import Layout from 'src/components/Layout';
import SEO from 'src/components/Seo';
import StyledContentWrapper from 'src/components/StyledContentWrapper';
import { H1, P } from 'src/components/Styled';

import * as colors from 'src/constants/colors';

const Styled = {
  ContentWrapper: styled(StyledContentWrapper)`
    margin-top: 102px;
    padding-top: 4rem;
    padding-bottom: 5rem;
  `,
};
const NotFoundPage = () => (
  <Layout page="404">
    <SEO title="404: Not found" />
    <Styled.ContentWrapper>
      <H1>404: Not Found</H1>
      <P>You just hit a route that doesn&#39;t exist... the sadness.</P>
    </Styled.ContentWrapper>
  </Layout>
);

export default NotFoundPage;
